.search-form-block {

    padding-bottom: 40px;

    &__wrap {
        width: 60%;
        margin: 0 auto;
    }
}

.search-form {

    text-align: center;

    input[type=text] {
        vertical-align: top;
        font-size: 1.125rem;
        color: #666666;
        background-color: #e6e6e6;
        padding: 0 20px;
        border: 0;
        line-height: 40px;
        width: 100%;
        margin: 0 0 0 0;
        display: inline-block;
        box-sizing: border-box;
    }

    input[type=submit] {
        vertical-align: top;
        padding: 0 20px;
        border: 0;
        line-height: 40px;
        margin: 0 0 0 2px;
        display: inline-block;
        background: #e6e6e6 url(/static/loup.svg) no-repeat center center;
        background-size: 25px;
        transition: opacity .3s;
        &:hover {
            opacity: .8;
        }
    }

    &__selectors {
        margin-top: 20px;
        margin-bottom: 30px;
        width: 100%;
        font-size: 0;
    }

    &__group {
        display: inline-block;
        margin: 0 0 30px 0;
        &_name_price {
            width: 30%;
        }
        &_name_artist {
            width: calc((70%) - 30px);
            margin-left: 30px;
        }
        &_name_color,
        &_name_ganre,
        &_name_size {
            width: 23.5%;
            margin-left: 2%;
        }
        &_name_technique {
            width: 23.5%;
        }

        .selector {
            display: block;
            &__options,
            &__selected {
                width: calc((100%) - 43px);
            }
        }
    }

    &__label {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: .875rem
    }

    .remove-search {
        &:before {
            width: 10px;
            height: 10px;
            margin-left: -20px;
            padding-right: 10px;
            content: "";
            display: inline-block;
            background: url(/static/remove.svg) no-repeat center;
            opacity: 0;
            transition: all .4s;
        }
        &:hover:before {
            opacity: 1;
        }
    }

}

.catalogs_last_yes .catalog {
    border-top: 1px solid $black;
    border-bottom: 0 !important;
    padding-bottom: 40px;
}

.catalogs_last_yes .catalog:empty {
    display: none;
}