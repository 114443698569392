.artist-card {
    &_size-small_true {
        @include col(4);
        @include col-offset(1, true);
        margin-bottom: 40px;

        &:nth-child(3n+1),
        &:nth-child(5n+1),
        &:nth-child(1) {
            margin-left: 8.33333% !important;
            margin-right: 8.33333% !important;
        }
        &:nth-child(2n+1) {
            @include col-offset(1, true);
        }
    }
    &_size-small_false {
        @include col(5);
        @include col-offset(0.5, true);
        margin-bottom: 40px;

        &:nth-child(3n+1),
        &:nth-child(5n+1),
        &:nth-child(1) {
            margin-left: 4.16667% !important;
            margin-right: 4.16667% !important;
        }
        &:nth-child(2n+1) {
            @include col-offset(0.5, true);
        }

        .artist-card__catalog {
            img {
                max-height: 260px;
            }
        }
    }
}

.artist {
    padding-top: 0;
    &__title {
        font-size: 1rem;
        padding: 10px 70px;
        margin-bottom: 0;
    }
}

.other__wrap {
    margin: 0;
}

.work-card {
    @include col(4);
    @include col-offset(1, true);
    margin-bottom: 20px;
    padding: 20px 0;

    &:first-child,
    &:nth-child(3n+1),
    &_size-small_true:nth-child(1),
    &_size-small_true:nth-child(5n+1) {
        @include col-offset(1, true);
        margin-left: 8.33333% !important;
        margin-right: 8.33333% !important;
    }
}

.catalog__works {
    margin: 0;
}

.artist__switcher {
    width: 100%;
}

.artist__switch {
    width: 50%;
    &:first-child {
        border-left: 0;
    }
    &:last-child {
        border-right: 0;
    }
}

.catalog-card {
    @include col(4);
    @include col-offset(1);
    margin-bottom: 20px;
    &:nth-child(3n+1),
    &:nth-child(5n+1),
    &:nth-child(1) {
        margin-left: 8.33333% !important;
        margin-right: 8.33333% !important;
    }
    &:nth-child(2n+1) {
        @include col-offset(1, true);
    }
}
