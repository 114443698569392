.newsletter-form {
    text-align: center;

    .form-control {
        font-size: 1.125rem;
        color: $gray;
        background-color: opacify($white, .9);
        padding: 0 20px;
        border: 0;
        line-height: 40px;
        width: 100%;
        max-width: 500px;
        margin: 25px auto;
        display: block;
        box-sizing: border-box;
    }

    .action-button {
        margin-bottom: 25px;
    }
}
