a {
    outline: 0;
}
.b-page__segment {
    margin: 0 auto;
    position: relative;
    &_wide_no {
        @include col-offset(2, true);
        @include cf();
        padding-bottom: 40px;
    }
}
.thin-fancybox .b-page__segment {
    width: 770px;
    overflow: hidden;
}
.wide-fancybox .b-page__segment {
    width: 900px;
    overflow: hidden;
}
.wrap {
    max-width: 1440px;
    padding: 40px 60px;
    margin: auto;
    box-sizing: border-box;
    @include cf();
}
.border {
    height: 100%;
    @include col(12);
    box-sizing: content-box;
    border: 1px solid #000000;
}
.border-without-bottom {
    height: 100%;
    @include col(12);
    box-sizing: content-box;
    border: 1px solid #000000;
    border-bottom: 0;
    .header {
        border: 0;
        border-bottom: 1px solid #000000;
    }
}
.border-without-bottom + .border {
    border-top: 0;
}
.without-border,
.no-border {
    height: 100%;
    @include col(12);
    box-sizing: content-box;
}
.without-border + .border {
    border: 0;
    .b-module {
        border: 0;
    }
}
.col-1 {
    @include col(1);
}
.col-2 {
    @include col(2);
}
.col-3 {
    @include col(3);
}
.col-4 {
    @include col(4);
}
.col-5 {
    @include col(5);
}
.col-6 {
    @include col(6);
}
.col-6-first {
    @include col(6,true);
    padding-left: 0;
    @include respond-to('tablet') {
        @include col(12)
    }
}
.col-6-last {
    @include col(6,true);
    padding-right: 0;
    @include respond-to('tablet') {
        @include col(12)
    }
}
.col-7 {
    @include col(7);
}
.col-8 {
    @include col(8);
}
.col-9 {
    @include col(9);
}
.col-10 {
    @include col(10);
}
.col-11 {
    @include col(11);
}
.col-12 {
    @include col(12);
}
.b-page_segment {
    border-bottom: 1px solid #000;
}
.b-page_segment:last-child {
    border-bottom: 0;
}
.b-page .b-page_segment__header {
    font-weight: 700;
    font-size: 0.875rem;
    letter-spacing: 1px;
}
.b-page {
    font-size: 14px;
    line-height: 1.5em;
}
.b-page p {
    margin-bottom: 1.2em;
    font-family: $helvetica;
    font-weight: 400;
    line-height: 1.5em;
    &:last-child {
        margin-bottom: 0;
    }
}
.b-page table {
    margin-bottom: 1.5em;
}
.b-page h1,
.b-page h2,
.b-page h3,
.b-page h4,
.b-page h5 {
    @include fheader();
    letter-spacing: 2px;
}
.b-page h1 {
    font-size: 2em;
    &:last-child {
        margin-bottom: 0;
    }
}
.b-page h2 {
    font-size: 1.8em;
}
.b-page h3 {
    font-size: 1.6em;
}
.b-page h4 {
    font-size: 1.4em;
}
.b-page h5 {
    font-size: 1.2em;
}
/* *********** */
.b-page .b-text {
    padding: 20px 0;
}
.b-page .b-text.block-with-picture {
    padding-top: 0;
}
/* *********** */
.b-page p.important {
    font-family: $museo;
    font-size: 1.25rem;
    margin-bottom: .8em;
}
.b-page p.big-important {
    font-family: $museo;
    font-size: 2.25rem;
    line-height: 1em;
    font-weight: 100;
    margin-bottom: .8em;
}
.b-page div.big-important {
    font-family: $museo;
    font-size: 2.25rem;
    line-height: 1em;
    font-weight: 100;
    margin: 0 auto .8em;
    width: 80%;
}
.b-page__back-link {
    height: 35px;
    width: 35px;
    border: 1px solid #000;
    position: absolute;
    left: -18px;
    top: 20px;
    background: white;
    background-image: url('../images/back.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 10px 10px;
    a {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }
}
.b-page__subheader {
    @include fheader();
    height: 50px;
    line-height: 50px;
    font-size: 0.8125rem;
    text-align: center;
    margin-bottom: 0;
    letter-spacing: 1px;
    font-weight: 500;
}
/* *********** */
.header {
    height: 85px;
    padding: 27px 0 0;
    border: 1px solid #000;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    h1 {
        @include fheader();
        font-size: 1.375rem;
        margin-bottom: .3em;
        display: block;
        font-weight: 500;
        letter-spacing: 0;
        text-transform: none;
        a {
            text-decoration: none;
        }
    }
    h2 {
        text-align: center;
        font-size: 0.8125rem;
        margin-bottom: 0;
        line-height: 1em;
        display: block;
    }
    &__titles {
        position: absolute;
        left: 50%;
        top: -15px;
        transform: translateX(-50%);
    }
    &__logotype {
        margin: 0 auto 5px;
        width: 78px;
        height: 25px;
        background: white;
        img {
            width: 78px;
            height: 25px;
            display: block;
        }
    }
    &__cart {
        float: right;
        margin-right: 30px;
        margin-top: 6px;
        text-transform: uppercase;
        font-family: $museo;
        font-size: 14px;
        display: none;
        a {
            text-decoration: none;
        }
        &_visible_yes {
            display: block;
        }
    }
    &__lang {
        float: left;
        margin-left: 30px;
        margin-top: 6px;
        text-transform: uppercase;
        font-family: $museo;
        font-size: 12px;
        a {
            text-decoration: none;
        }
    }
    &__search {
        float: right;
        margin-right: 30px;
        a {
            display: block;
            width: 30px;
            height: 30px;
            background: url(/static/loup.svg) no-repeat center center;
            background-size: contain;
            transition: opacity .3s;
            &:hover {
                opacity: .6;
            }
        }
    }
}
.sub-segment__header {
    padding: 25px;
    h3 {
        font-size: 0.875rem;
        margin: 0;
    }
}
.padding {
}
.b-page_url_slider_section {
    border-bottom: 0;
}
.b-page_url_about_section {
    padding: 20px 0;
}
.b-page_url_peek_section {
    border-bottom: 0;
}
.b-module {
    border-top: 1px solid #bdbdbd;
    &:first-child {
        border-top: 0;
    }
}
.fr-arrows {
    position: absolute;
    top: 0;
    width: 150px;
    height: 100%;
    box-sizing: border-box;
    z-index: 1000;
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: center;
    cursor: pointer;
    transition: all .3s;
    opacity: .5;
    &_mode_left {
        left: 0;
        background-image: url(../images/fr-arrows_mode_left_white.svg);
        background-position: top 50% left 20px;
    }
    &_mode_right {
        right: 0;
        background-image: url(../images/fr-arrows_mode_right_white.svg);
        background-position: top 50% right 20px;
    }
    &:hover {
        opacity: 1;
    }
}
.b-page {
    .swiper-pagination-bullet {
        margin-right: 15px !important;
        margin-left: 15px !important;
        width: 5px;
        height: 5px;
        opacity: 1;
        background-color: #fff;
    }
    .swiper-pagination-bullet-active {
        background-color: #fff;
        height: 12px;
        width: 12px;
        margin-left: 11px !important;
        margin-right: 12px !important;
        position: relative;
        top: 3px;
    }
}
.block-with-picture {
    &:before {
        display: block;
        width: 120px;
        height: 100px;
        content: "";
        margin: -50px auto 0;
        background-color: #fff;
        background-position: center;
        background-repeat: no-repeat;
    }
    &_picture_1 {
        &:before {
            background-image: url(/static/block-with-picture_picture_1.jpg);
        }
    }
    &_picture_2 {
        &:before {
            background-image: url(/static/block-with-picture_picture_2.png);
        }
    }
    &_picture_3 {
        &:before {
            background-image: url(/static/block-with-picture_picture_3.png);
        }
    }
    &_picture_4 {
        &:before {
            background-image: url(/static/block-with-picture_picture_4.png);
        }
    }
    &_picture_5 {
        &:before {
            background-image: url(/static/block-with-picture_picture_5.png);
        }
    }
}
.gotopage {
    margin: 20px 0;
    text-align: center;
    width: 100%;
    display: block;
    font-weight: 600;
    color: black;
}
