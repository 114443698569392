.news-item__info {
    width: 75%;
}

.news__item_size_full {

    padding-top: 10px;

    .news__title {
        font-size: 2em;
    }
    .news__info {
        width: auto;
        margin: 0 20px;
    }
}
