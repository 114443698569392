.artist-card {
    &_size-small_false,
    &_size-small_true {
        @include col(10);
        @include col-offset(1, true);
        margin-bottom: 40px;

        &:nth-child(3n+1),
        &:nth-child(5n+1),
        &:nth-child(1) {
            margin-left: 8.33333% !important;
            margin-right: 8.33333% !important;
        }
        &:nth-child(2n+1) {
            @include col-offset(1, true);
        }
    }
}

.other__wrap {
    margin: 0;
}

.work {
    &__title {
        padding-left: 8.33333% !important;
        padding-right: 8.33333% !important;
        box-sizing: border-box;
        line-height: 1.2em;
        font-size: 1.3rem;
    }
    &__author {
        font-size: 1rem;
        padding: 10px 70px;
    }

    &__images-wrap {
        display: none;
    }
    &__images-mobile-wrap {
        display: block;
        margin: 0 0 20px 0;
    }
    &__images-mobile-image {
        width: 100%;
        margin: 0 auto;
    }
}

.work-card {
    @include col(12);
    @include col-offset(0, true);
    margin-bottom: 20px;
    padding: 20px 0;
    margin-left: 0 !important;
    margin-right: 0 !important;

    &:first-child,
    &:nth-child(3n+1),
    &_size-small_true:nth-child(1),
    &_size-small_true:nth-child(5n+1) {
        @include col-offset(0, true);
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    &__info {
        padding-left: 8.33333% !important;
        padding-right: 8.33333% !important;
        box-sizing: border-box;
    }

    &__image {
        background: none !important;
        height: auto;
        min-height: 150px;
    }

    &__picture {
        display: block;
        width: 100%;
        margin: 0 auto;
        max-height: inherit;
        /*
        max-width: 100%;
        */
    }

    &_size-small_true {
        height: auto;
        .work-card__image {
            height: auto;
            min-height: 100px;
        }
    }
}

.catalog__works {
    margin: 0;
}

.artist__title {
    padding: 10px 70px;
    margin-bottom: 0;
}
.bio__wrap {
    margin-left: 20px;
    margin-right: 20px;
}

.artist-events__wrap,
.pdfs__wrap,
.exhibitions-publications__wrap {
    margin: 0;
}
.artist-events__item,
.pdfs__item,
.apubs .apub {
    @include col(10);
    @include col-offset(1, true);
    margin-bottom: 20px;
    padding-bottom: 20px;
    &:first-child, &:nth-child(3n+1) {
        @include col(10);
        @include col-offset(1, true);
        margin-bottom: 20px;
    }
}

.exhibitions-publications__col_collapsed_yes {
    height: auto;
    overflow: visible;
    &:after {
        display: none;
    }
}
.exhibitions-publications__more {
    display: none;
}
.album__text-wrap,
.exhibitions-publications__col {
    margin-left: 20px !important;
    margin-right: 20px !important;
    width: auto;
    float: none;
}

.work__info {
    padding-top: 70px;
}

.work__info {
    @include col(12);
    float: none;
}

.work__images-wrap {
    width: 100%;
    float: none;
    margin-left: 0;
}
.work__right-part {
    float: none;
    padding-left: 0;
}

.album__info {
    padding-top: 70px;
}

.album__info {
    @include col(12);
    float: none;
}

.album__images-wrap {
    width: 100%;
    float: none;
    margin-left: 0;
}
.album__right-part {
    float: none;
    padding-left: 0;
}

.catalog-card {
    @include col(10);
    @include col-offset(1, true);
    margin-bottom: 20px;
    &:first-child, &:nth-child(5n+1) {
        @include col(10);
        @include col-offset(1, true);
        margin-bottom: 20px;
    }
}
