.event-item_size_small {
    @include col(4);
    @include col-offset(1, true);
    &:nth-child(3n+1) {
        @include col-offset(1, true);
    }
    &:nth-child(6n+1) {
        @include col-offset(1, true);
    }
}

.blog {
    margin-left: 8.33333% !important;
    margin-right: 8.33333% !important;
}

.gutter-sizer {
	width: 2%;
}

.event-item_type_card {
	width: 48%;
}