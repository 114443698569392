.menu {

    @include cf();

    text-align: center;
    margin-top: -12px;
    position: relative;
    z-index: 1;

    > ul {
        list-style: none;
        display: inline-block;
        text-align: center;
        background-color: white;
        margin: 0;
        padding: 0 15px 30px;
        position: relative;

        > li {

            a:after {
                display: inline-block;
                width: 10px;
                height: 10px;
                background: url('/static/menu.svg') no-repeat;
                background-size: 10px 10px;
                content: '';
                margin-left: 5px;
                margin-right: -5px;
                transform-origin: 5px 5px;
                transition: all .4s;
                opacity: .5;
            }

            a:first-child:last-child:after {
                display: none;
            }
        }

        > li:hover {
            a:after {
                transform: rotate(90deg);
            }
        }

    }

    li {
        list-style: none;
        vertical-align: top;
        display: inline-block;
        margin: 0;
        padding: 0;
        z-index: 0;

        ul {
            z-index: -1;
            position: absolute;
            display: inline-block;
            opacity: 0;
            margin: 0;
            padding: 0;
            font-size: 14px;
            width: 100%;
            left: 0;
            top: 25px;
            transition: all .4s;
            background-color: white;

            a {
                line-height: 25px;

                &:hover {
                    color: $gray;
                }

            }
        }
    }

    li:hover {
        ul {
            opacity: 1;
            z-index: 10;
        }
    }

    li.active {

        z-index: 1;
        > a {
            color: $red;

            &:hover {
                color: $gray;
            }

            &:after {
                transform: rotate(90deg);
            }
        }

        ul {
            opacity: 1;
            z-index: 2;
        }
    }

    li.active.nohover:not(:hover) {
        > a {
            &:after {
                transform: none;
            }
        }
    }

    a {
        padding: 0 15px;
        text-decoration: none;
        box-sizing: border-box;
        text-transform: uppercase;
        letter-spacing: 1px;

        line-height: 25px;
        font-family: $museo;
        text-align: center;

        transition: color .3s ease, background-color .3s ease;

    }

}