.event-item_size_small {
    @include col(10);
    @include col-offset(1, true);
    &:nth-child(3n+1) {
        @include col-offset(1, true);
    }
    &:nth-child(6n+1) {
        @include col-offset(1, true);
    }
    .event-item {
        &__image {
            height: 250px;
            @include background-contain();
            background-position: center center !important;
            margin-bottom: 10px;
        }
        &__title {
            @include fheader();
            text-align: left;
            font-weight: normal;
            font-size: 18px;
            text-transform: none;
        }
        &__info {
            max-width: 100%;
            margin-bottom: 20px;
        }
    }
}

.blog {
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.gutter-sizer {
	width: 10%;
}

.event-item_type_card {
	width: 100%;
}

.event-item_size_big {
    height: auto;
    @include cf();
    @include col(10);
    @include col-offset(1, true);
    border-bottom: 0;
    margin-bottom: 20px;
    margin-top: 20px;
    .event-item__info,
    .event-item__image {
        @include col(10);
        @include col-offset(1, true);
    }
    .event-item__image {
        min-height: 300px;
        margin-bottom: 10px;
        background-position: center center!important;
    }
    .event-item__title {
        @include fheader();
        text-align: left;
        font-weight: normal;
        font-size: 18px;
        text-transform: none;
    }
}
.events-homepage {
    .event-item_size_small {
        height: auto;
        @include cf();
        @include col(10);
        @include col-offset(1, true);
        .event-item__info,
        .event-item__image {
        }
        .event-item__image {
            min-height: 300px;
            margin-bottom: 10px;
            background-position: center center!important;
        }
        .event-item__title {
            @include fheader();
            text-align: center;
            font-weight: normal;
            font-size: 18px;
            text-transform: none;
        }
        .event-item__information {
            text-align: center;
        }
    }
}
.event-item_size_middle {
    @include col(10);
    @include col-offset(1, true);

    margin-bottom: 20px;

    float: none;

    /*
        &:nth-child(3n+1) {
            margin-left: 0;
        }

        &:nth-child(6n+1) {
            @include col-offset(0.2);
        }
    */
    .event-item {
        &__image {
            height: 250px;
            @include background-contain();
            background-position: center center !important;
            margin-bottom: 10px;
        }
        &__title {
            @include fheader();
            text-align: left;
            font-weight: normal;
            font-size: 18px;
            text-transform: none;
        }
        &__info {
            max-width: 100%;
            margin-bottom: 20px;
        }
    }
}
