.action-button {
    border: 2px solid $black;
    display: inline-block;
    text-align: center;
    font-weight: 900;
    font-family: $museo;
    height: 60px;
    line-height: 60px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    box-sizing: border-box;
    padding: 0 25px;
    position: relative;
    background-color: $white;
    outline: none;
    white-space: nowrap;
    letter-spacing: 1px;
    color: #231f20;
}

.action-button:hover {
    outline: 2px solid $black;
    outline-offset: -4px;
    color: #231f20;
}
.action-button:active {
    background-color: #414042;
    border-color: #414042;
    outline: 2px solid #414042;
    outline-offset: -4px;
    color: $white;
}

.action-button_show_ep {
    // margin-top: 100px;
}

/*
.action-button:before {
    content: "";
    width: 100%;
    height: 100px;
    position: absolute;
    left: 50%;
    bottom: 100%;
    margin-bottom: 2px;
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
    display: none;
}
.action-button_image_1:before {
    display: block;
    background-image: url(../images/action-button_image_1.png);
}
.action-button_image_2:before {
    display: block;
    background-image: url(../images/action-button_image_2.png);
}
.action-button_image_3:before {
    display: block;
    background-image: url(../images/action-button_image_3.png);
}
.action-button_image_4:before {
    display: block;
    background-image: url(../images/action-button_image_4.png);
}
.action-button_image_5:before {
    display: block;
    background-image: url(../images/action-button_image_5.png);
}
.action-button_image_6:before {
    display: block;
    background-image: url(../images/action-button_image_6.png);
}
.action-button_image_7:before {
    display: block;
    background-image: url(../images/action-button_image_7.png);
}
.action-button_image_8:before {
    display: block;
    background-image: url(../images/action-button_image_8.png);
}
*/

.action-button_font_large {
    font-size: 1.25rem;
}

@mixin action_button_2() {
    display: inline-block;
    line-height: 40px;
    font-size: 1rem;
    height: 40px;
    padding: 0 15px;
    text-decoration: none;
    transition: color .3s ease, background-color .3s ease, opacity .3s ease;
    overflow: hidden;
    white-space: nowrap;
}

.action-button-2 {
    @include action_button_2();
}

input[type=submit].action-button-2,
button.action-button-2 {
    border: 0;
}

.action-button-2_theme_gray {
    &,
    &:link,
    &:visited {
        background-color: #e6e6e6;
        color: $black;
    }
    &:hover,
    &:active {
        background-color: opacify($white, .95);
    }
}

.action-button-2_mode_selector {
    &:after {
        display: inline-block;
        content: "";
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: center right;
        background-size: 16px 16px;
        margin-left: 10px;
        line-height: 40px;
        padding: 12px 0 12px 12px;
        vertical-align: middle;
        // background-image: url('data:image/svg+xml;utf-8,<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="#ffffff" d="M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z"/></svg>');
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTc5MiIgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGw9IiNmZmZmZmYiIGQ9Ik0xMzk1IDczNnEwIDEzLTEwIDIzbC00NjYgNDY2cS0xMCAxMC0yMyAxMHQtMjMtMTBsLTQ2Ni00NjZxLTEwLTEwLTEwLTIzdDEwLTIzbDUwLTUwcTEwLTEwIDIzLTEwdDIzIDEwbDM5MyAzOTMgMzkzLTM5M3ExMC0xMCAyMy0xMHQyMyAxMGw1MCA1MHExMCAxMCAxMCAyM3oiLz48L3N2Zz4=');
    }
}

.action-button-2_mode_selector.action-button-2_theme_gray {
    &:after {
        // background-image: url('data:image/svg+xml;utf-8,<svg width="1792" height="1792" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path fill="#000000" d="M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z"/></svg>');
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTc5MiIgaGVpZ2h0PSIxNzkyIiB2aWV3Qm94PSIwIDAgMTc5MiAxNzkyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGw9IiMwMDAwMDAiIGQ9Ik0xMzk1IDczNnEwIDEzLTEwIDIzbC00NjYgNDY2cS0xMCAxMC0yMyAxMHQtMjMtMTBsLTQ2Ni00NjZxLTEwLTEwLTEwLTIzdDEwLTIzbDUwLTUwcTEwLTEwIDIzLTEwdDIzIDEwbDM5MyAzOTMgMzkzLTM5M3ExMC0xMCAyMy0xMHQyMyAxMGw1MCA1MHExMCAxMCAxMCAyM3oiLz48L3N2Zz4=');
        border-left: 2px solid white;
    }
}

.action-button-2:empty {
    &:before {
        // margin-right: 0;
    }
}

.action-button-2_mode_seperated {
    margin-bottom: 1px;
}

.selector {
    overflow: visible;
    position: relative;
    cursor: pointer;
    user-select: none;
    &__options {
        position: absolute;
        left: 0;
        top: 100%;
        width: auto;
        height: auto;
        z-index: 1000;
        box-shadow: 2px 2px 3px opacify($white, .7);
        display: none;
        text-align: left;
    }
    &__selected {
        height: 40px;
        vertical-align: top;
        overflow: hidden;
        display: inline-block;
        text-align: left;
    }
    &__window {
        cursor: default;
        position: absolute;
        right: 0;
        top: 65px;
        min-width: 300px;
        height: auto;
        z-index: 1000;
        box-shadow: 0px 0px 10px opacify($white, .7);
        display: block;
        background-color: $white;
        color: $black;
        text-align: left;
        z-index: 1;
        display: none;

        &:after {
            display: block;
            content: "";
            width: 20px;
            height: 20px;
            transform: rotate(45deg);
            position: absolute;
            right: 50px;
            top: -10px;
            background-color: $white;
            z-index: -1;
            box-shadow: -1px -1px 2px opacify($white, .8);
        }

        .b-page__wrapper {
            width: auto;
            padding: 0;
        }
        .b-page__crumbs {
            display: none;
        }
    }

    &_state_pressed {
        .selector__options {
            display: block;
        }
        .selector__window {
            display: block;
        }
    }
}

.action-button-2_theme_gray.selector {
    .selector__option {
        @include action_button_2();
        background-color: #f4f4f4;
        color: $black;
        display: block;
        cursor: pointer;
        &:hover {
            background-color: opacify($white, .85);
        }
    }
}

.action-button_mode_inactive {
    opacity: .6;
    cursor: default;
    &:hover {
        outline: none;
    }
    &:active {
        color: #231f20;
        background-color: white;
    }
}
