@font-face {
    font-family: 'museo_slab';
    src: url('../fonts/museo_slab_100-webfont.eot');
    src: url('../fonts/museo_slab_100-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/museo_slab_100-webfont.woff') format('woff'),
         url('../fonts/museo_slab_100-webfont.ttf') format('truetype');
    font-weight: 100;       // For normal width. It could has another value
    font-style: normal;     // Also could has another value
}
@font-face {
    font-family: 'museo_slab';
    src: url('../fonts/museo_slab_500-webfont.eot');
    src: url('../fonts/museo_slab_500-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/museo_slab_500-webfont.woff') format('woff'),
         url('../fonts/museo_slab_500-webfont.ttf') format('truetype');
    font-weight: 500;       // For normal width. It could has another value
    font-style: normal;     // Also could has another value
}
@font-face {
    font-family: 'museo_slab';
    src: url('../fonts/museo_slab_700-webfont.eot');
    src: url('../fonts/museo_slab_700-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/museo_slab_700-webfont.woff') format('woff'),
         url('../fonts/museo_slab_700-webfont.ttf') format('truetype');
    font-weight: 700;       // For normal width. It could has another value
    font-style: normal;     // Also could has another value
}
@font-face {
    font-family: 'museo_slab';
    src: url('../fonts/museo_slab_900-webfont.eot');
    src: url('../fonts/museo_slab_900-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/museo_slab_900-webfont.woff') format('woff'),
         url('../fonts/museo_slab_900-webfont.ttf') format('truetype');
    font-weight: 900;       // For normal width. It could has another value
    font-style: normal;     // Also could has another value
}
/*
@font-face {
    font-family: 'helvetica_neue';
    src: url('../fonts/helveticaneuecyr-medium-webfont.eot');
    src: url('../fonts/helveticaneuecyr-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/helveticaneuecyr-medium-webfont.woff') format('woff'),
         url('../fonts/helveticaneuecyr-medium-webfont.ttf') format('truetype');
    font-weight: 400;       // For normal width. It could has another value
    font-style: normal;     // Also could has another value
}
@font-face {
    font-family: 'helvetica_neue';
    src: url('../fonts/helveticaneuecyr-bold-webfont.eot');
    src: url('../fonts/helveticaneuecyr-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/helveticaneuecyr-bold-webfont.woff') format('woff'),
         url('../fonts/helveticaneuecyr-bold-webfont.ttf') format('truetype');
    font-weight: 600;       // For normal width. It could has another value
    font-style: normal;     // Also could has another value
}
*/
$museo: 'museo_slab', serif;
$helvetica: helvetica, sans-serif;
