.b-page__back-link {
    left: 0;
    top: 0;
    border: 0;
}

.fr-arrows {
	width: 80px;
}

.b-page__segment_wide_no {
	margin-left: 10px;
	margin-right: 10px;
}

.b-text img {
	float: none !important;
	display: block;
	max-width: 100%;
	margin: 20px auto !important;
	width: auto !important;
	height: auto !important;
}