.basket__order form {
	width: auto;
	margin: 40px 20px 0;
	max-width: inherit;
}

.basket__wrapper {
	margin: 0;
}

.basket__image {
	float: none;
	width: auto;
	margin-bottom: 20px;
	min-height: auto;
}

.basket__wrap {
	width: auto;
	float: none;
	margin: 0 20px;
}