.artists {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &_template_homepage {
        @include col-offset(0.5, true);
    }
    &_template_index {
        @include col-offset(0.5, true);
        padding: 40px 0 0 0;
        .artist-card {
            margin-bottom: 40px;
        }
    }
    &__more {
        margin-top: 20px;
        height: 40px;
        line-height: 40px;
        text-align: center;
    }
}
.artist-card {
    @include col(3.8);
    @include col-offset(0.3);
    float: none;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 150px;
    &:first-child,
    &:nth-child(3n+1) {
        margin-left: 0;
    }
    &__wrap {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: flex-end;
        flex: 1;
    }
    &__catalog {
        margin-bottom: 25px;
        &:empty {
            margin: 0;
        }
        img {
            max-height: 300px;
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }
        a {
            display: block;
        }
    }
    &__artist {
        width: 100%;
        text-align: center;
        &-image {
            width: 65px;
            height: 65px;
            border-radius: 50%;
            margin: 0 auto 15px;
            @include background-cover;
            background-size: contain;
        }
        &-title {
            @include fheader();
            font-size: 1.375rem;
            margin-bottom: 0.2em;
            text-transform: none;
        }
    }
    &_size-small_true {
        @include col(2.2);
        @include col-offset(0.25);
        float: none;
        &:nth-child(5n+1),
        &:nth-child(1) {
            margin-left: 0 !important;
        }
        &:nth-child(3n+1) {
            @include col-offset(0.25);
        }
        .artist-card__artist {
            height: auto;
        }
    }
}
.artist {
    padding-top: 20px;
    &__info {
        text-align: center;
        border-bottom: 1px solid $black;
    }
    &__image {
        width: 100%;
        height: 400px;
        background-size: cover;
        background-position: center top;
    }
    &__title {
        @include fheader();
        font-size: 2.5rem;
        margin-bottom: 1em;
        letter-spacing: 2px;
    }
    &__switcher {
        text-align: center;
        font-size: 0;
        margin-top: -30px;
    }
    &__switch {
        @include mfont();
        border: 1px solid $black;
        display: inline-block;
        letter-spacing: 1px;
        width: 260px;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
        font-weight: 500;
        text-transform: uppercase;
        position: relative;
        background-color: $white;
        a {
            display: block;
            text-align: center;
            font-size: 1rem;
            text-decoration: none;
        }
        &:first-child {
            border-right: 0;
        }
        &_active_yes {
            &:after {
                display: block;
                border: 1px solid $black;
                border-right: 0;
                border-bottom: 0;
                width: 10px;
                height: 10px;
                content: "";
                transform: rotate(45deg);
                position: absolute;
                left: calc(50% - 10px);
                bottom: -6px;
                background-color: $white;
            }
        }
    }
    .lSPrev {
        display: none;
    }
    .lSNext {
        display: none;
    }
    .fr-arrows_mode_left,
    .fr-arrows_mode_right {
        margin-top: 20px;
    }
}
.catalog {
    padding-top: 25px;
    border-bottom: 1px solid $black;
    &__title {
        @include fheader();
        //margin-bottom: 0;
        letter-spacing: 1px;
        padding: 0 4.16667%;
    }
    &__available {
        text-align: center;
        font-size: 0.8125rem;
        margin-bottom: 15px;
    }
    &__description {
        @include col-offset(0.5, true);
        text-align: center;
        font-size: 0.8125rem;
        margin-bottom: 15px;
    }
    &__works {
        // background: rgba(0,0,0,.4);
        @include col-offset(0.5, true);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .work-card:first-child:last-child {
            margin-left: auto;
            margin-right: auto;
            float: none;
        }
    }
    &_size_small {
        border-bottom: 0;
    }
}
.work-card {
    @include col(3.8);
    @include col-offset(0.3);
    margin-bottom: 20px;
    padding: 20px;
    float: none;
    min-height: 150px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    &:first-child,
    &:nth-child(3n+1) {
        margin-left: 0;
    }
    a {
        text-decoration: none;
    }
    &__image {
        max-width: 100%;
        margin: 0 auto 15px auto;
        position: relative;
    }
    &__picture {
        max-height: 300px;
        max-width: 100%;
        display: block;
    }
    &__wrap {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: flex-end;
        flex: 1;
        transition: all .15s ease-in-out;
        &:hover {
            transform: scale(1.02);
            filter: brightness(1.1);
            &__picture {
                box-shadow: 0 0 4px rgba(0,0,0,.4);
            }
        }
    }
    &__info {
        text-align: center;
        height: 63px;
        overflow: hidden;
        &_three-line_yes {
            height: 84px;
        }
    }
    &__title {
        max-height: 42px;
        overflow: hidden;
    }
    &__price, &__soldout {
        text-align: center;
        font-size: .8rem;
    }
    &__soldout {
        display: none;
    }
    &__last {
        text-align: center;
        color: white;
        text-transform: uppercase;
        border: 2px solid white;
        font-size: 18px;
        font-family: $museo;
        font-weight: bold;
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        padding: 5px 10px;
    }
    &__artist {
        @include fheader();
        font-size: 1.375rem;
        margin-bottom: 0em;
        text-transform: none;
        a {
            text-decoration: none;
        }
    }
    &_size-small_true {
        @include col(2.2);
        @include col-offset(0.25);
        float: none;
        &:nth-child(1),
        &:nth-child(5n+1) {
            margin-left: 0 !important;
        }
        &:nth-child(3n+1) {
            @include col-offset(0.25);
        }
        .work-card__image {
            background-position: left bottom;
        }
        .work-card__title {
            font-weight: 600;
            text-decoration: underline;
        }
        .work-card__price {
        }
        .work-card__soldout {
            display: block;
        }
        .work-card__info {
            width: 100%;
            text-align: center;
        }
        .work-card__last {
            display: none;
        }
    }
}
.interiors {
    border-bottom: 1px solid $black;
    &__wrap {
        // @include col-offset(0.5, true);
        margin-right: 0;
        padding-top: 20px;
        position: relative;
        // padding-bottom: 40px;
    }
    &__title {
        @include fheader();
        letter-spacing: 1px;
    }
    &__slider {
        height: 360px;
        overflow: hidden;
    }
    img {
        height: 100%;
    }
}
.other {
    &__wrap {
        @include col-offset(0.5, true);
        @include cf();
        padding-top: 20px;
        padding-bottom: 40px;
    }
    &__title {
        @include fheader();
        letter-spacing: 1px;
    }
    &__slider {
        display: flex;
        flex-wrap: wrap;
        .artist-card {
            margin-bottom: 20px;
        }
    }
}
.bio {
    border-bottom: 1px solid $black;
    &__wrap {
        @include col-offset(2, true);
        @include cf();
        padding-bottom: 40px;
    }
}
.exhibitions-publications {
    border-bottom: 1px solid $black;
    &__wrap {
        @include col-offset(1, true);
        @include cf();
        padding-bottom: 80px;
    }
    &__wrapper {
        @include cf();
    }
    &__col {
        @include col(5);
        @include col-offset(1, true);
        overflow: hidden;
        position: relative;
        strong {
            font-family: $museo;
        }
        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
        &_collapsed_yes {
            height: 400px;
            overflow: hidden;
            position: relative;
        }
        &_collapsed_yes:after {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 50px;
            width: 100%;
            display: block;
            content: "";
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        }
    }
    &__col-full {
        @include col(10);
        @include col-offset(1, true);
        &:last-child {
            margin-right: unset;
        }
    }
    &_only_one {
        .exhibitions-publications__col {
            @include col-offset(3.5, true);
        }
    }
    &__more {
        text-align: center;
        padding: 20px 0 0 0;
        clear: both;
    }
}
.apubs {
    @include cf();
    .apub {
        @include col(3.8);
        @include col-offset(0.3);
        margin-bottom: 20px;
        &:first-child,
        &:nth-child(3n+1) {
            margin-left: 0;
        }
        &__image {
            width: 25%;
            float: left;
            img {
                max-width: 100%;
            }
        }
        &__wrap {
            margin-left: 30%;
        }
        &__logo {
            width: 100px;
            margin-bottom: 5px;
            img {
                max-width: 100%;
            }
        }
        &__title {
            margin-bottom: 5px;
        }
    }
    .apub:first-child:last-child {
        margin-left: auto;
        margin-right: auto;
        float: none;
    }
    .apub:first-child:nth-last-child(2) {
        @include col-offset(2);
    }
}
.pdfs {
    border-bottom: 1px solid $black;
    // &__wrap {
    //     @include col-offset(0.5, true);
    //     @include cf();
    //     padding-bottom: 20px;
    // }
    &__wrap {
        @include col-offset(2, true);
        @include cf();
        padding-bottom: 40px;
    }
    &__item {
        // @include col(3.8);
        // @include col-offset(0.3);
        // height: 100px;
        // margin-bottom: 20px;
        // &:first-child,
        // &:nth-child(3n+1) {
        //     margin-left: 0;
        // }
        font-size: 20px;
        padding: 10px 0;
        strong {
            font-family: $museo;
        }
        a {
            // box-sizing: border-box;
            // display: block;
            // height: 100%;
            // width: 100%;
            // background: #F4F4F4;
            // text-decoration: none;
        }
    }
    // &__title {
    //     margin-bottom: 10px;
    // }
    // &__desc {
    //     height: 40px;
    //     overflow: hidden;
    // }
    // .pdfs__item:first-child:last-child {
    //     margin-left: auto;
    //     margin-right: auto;
    //     float: none;
    // }
    // .pdfs__item:first-child:nth-last-child(2) {
    //     @include col-offset(2);
    // }
}
.tags {
    border-top: 1px solid $black;
    &__wrap {
        @include col-offset(2, true);
        @include cf();
        padding-bottom: 20px;
    }
    &__item {
        display: inline-block;
        margin: 0 5px 10px 0;
        a {
            font-family: $museo;
            box-sizing: border-box;
            padding: 15px 30px;
            display: inline-block;
            background: #F4F4F4;
            text-decoration: none;
        }
    }
}
.artist-events {
    &__wrap {
        @include col-offset(2, true);
        @include cf();
        padding-bottom: 20px;
    }
    &__item {
        display: block;
        margin: 0 0 30px 0;
    }
    &__date {
        font-family: $museo;
        font-size: 1.25rem;
        font-weight: 700;
    }
    &__title {
        margin-bottom: 10px;
    }
    &__image {
        text-align: center;
        margin: 0 auto;
        max-width: 80%;
        img {
            max-width: 100%;
        }
    }
}
.catalog-card {
    @include col(2.2);
    @include col-offset(0.25);
    padding: 20px 0;
    margin-bottom: 20px;
    &:first-child,
    &:nth-child(5n+1) {
        margin-left: 0;
    }
    &__title {
        font-family: $museo;
        font-size: 1.375rem;
        line-height: 1.1em;
        margin-bottom: 5px;
    }
    &__image {
        margin: 0 0 15px 0;
        width: 85px;
        height: 85px;
        img {
            max-width: 100%;
            display: block;
        }
    }
}
.work {
    &__info {
        padding-top: 30px;
        position: relative;
    }
    &__right-part {
        padding-left: 69.16667%;
        &-wrap {
            padding: 0 20px;
        }
    }
    &__author {
        @include mfont();
        font-size: 1.875rem;
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
        position: relative;
        text-transform: uppercase;
    }
    &__title {
        font-weight: 500;
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 10px;
    }
    &__desc {
        font-size: 0.875rem;
        text-align: center;
        font-weight: 400;
        margin-bottom: 40px;
    }
    &__images-wrap {
        @include col(8);
        @include col-offset(0.3);
        // height: 500px;
        margin-bottom: 20px;
        position: relative;
        .fotorama__nav-wrap {
        }
        .fotorama__dot {
            border-color: rgba(0, 0, 0, .7);
            background: rgba(0, 0, 0, .5);
        }
        .fotorama__nav--dots .fotorama__nav__frame {
            width: 40px;
            height: 40px;
        }
        .fotorama__nav__frame .fotorama__dot {
            margin: 0 auto;
            left: 0;
            top: 17px;
        }
        .fotorama__nav__frame.fotorama__active .fotorama__dot {
            border-width: 1px;
            width: 11px;
            height: 11px;
            top: 13px;
            background: rgba(0, 0, 0, .5);
        }
    }
    &__images-mobile-wrap {
        display: none;
    }
    &__image {
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    &__prices {
        padding: 0 0 25px 0;
        @include cf();
    }
    &__price {
        padding: 0 0 30px 0;
        margin-left: 0;
        &-size {
            font-family: $museo;
            margin-bottom: 10px;
            font-weight: 700;
            font-size: 1.375rem;
        }
        &-type {
            font-size: 0.75rem;
            line-height: 1rem;
            margin-bottom: 5px;
        }
        &-edition {
            font-size: 0.75rem;
            line-height: 1rem;
            margin-bottom: 5px;
        }
        &-price {
            font-size: 0.75rem;
            line-height: 1rem;
            font-weight: 600;
            margin-bottom: 5px;
        }
        &-desc {
            font-size: 0.75rem;
            line-height: 1rem;
            margin-bottom: 5px;
        }
        &-mounting {
            font-size: 0.75rem;
            line-height: 1rem;
            margin-bottom: 15px;
            color: $gray;
        }
        &-remove:before {
            width: 10px;
            height: 10px;
            margin-left: -20px;
            padding-right: 10px;
            content: "";
            display: inline-block;
            background: url(/static/remove.svg) no-repeat center;
            opacity: 0;
            transition: all .4s;
        }
        &-remove:hover:before {
            opacity: 1;
        }
        &_soldout_yes {
            opacity: .3;
        }
        &-buy {
            .action-button {
                font-size: 1rem;
                font-weight: 700;
                padding: 5px 20px;
                height: auto;
                line-height: 20px;
                white-space: inherit;
                max-width: 160px;
                &:before {
                    display: none;
                }
            }
        }
    }
}
.catalogs_placement_inside {
    border-top: 1px solid $black;
    .catalog {
        border-bottom: 0;
    }
}
.pick-home {
}
.albums-home {
    border-top: 1px solid #bdbdbd;
    padding-top: 80px;
}
.albums-homepage {
    border-top: 1px solid #bdbdbd;
}
.events-homepage {
    border-top: 1px solid #bdbdbd;
}
