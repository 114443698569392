.share {
	margin: 40px auto;
	width: calc((100%) - 20px);
	max-width: 500px;

	&__wrap {
	}

	&__title {
		border: 1px solid black;
		border-bottom: 0;
		@include fheader();
		font-size: 18px;
		line-height: 50px;
		text-align: center;
		margin: 0;
		box-sizing: border-box;
	}

	&__block {
		box-sizing: border-box;
		border: 1px solid black;
		display: inline-block;
		line-height: 50px;
		width: 50%;
		text-align: center;

		a {
			display: block;

			&:before {
				display: inline-block;
				content: "";
				width: 25px;
				height: 25px;
				vertical-align: middle;
				margin-right: 5px;
				background-size: 25px;
				background-position: center;
				background-repeat: no-repeat;
			}
		}

		&_mode_fb:before {
			background-image: url(/static/fb-icon.svg);
		}
		&_mode_tw:before {
			background-image: url(/static/tw-icon.svg);
		}
	}
	&__block + &__block {
		border-left: 0;
	}
}
