/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    margin-bottom: 2em;
    li {
        margin-left: 1.5em;
    }
}


blockquote, q {
    quotes: none;
}

strong {
    font-weight: 700;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* My own styles */

html, body {
    background: $white;
    color: $black;
    font-family: $helvetica;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: $black;
    text-decoration: underline;
    transition: opacity .3s, color .3s;
    -webkit-transition: opacity .3s, color .3s;
}

a:visited {
    color: $black;
}

a:hover {
    color: $gray;
    text-decoration: none;
}

a:active {
}

.alert {
    padding: 40px;
    text-align: center;
    border-radius: 5px;
    margin: 20px 0;
    font-size: 20px;
}

.site-error {
    width: 1000px;
    margin: 50px auto;
    padding: 20px;
}

.fancybox-inner {
    background: #fefefe;
}

.fancybox-inner > .alert {
    margin: 0;
    border-radius: 0;
}

.cc {
    @include clear();
}

.vertical-middle {
    &__table {
        display: table;
        width: 100%;
        height: 100%;
    }
    &__tr {
        display: table-row;
        width: 100%;
        height: 100%;
    }
    &__td {
        display: table-cell;
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }
}

.h_size_mobile,
.h_size_tablet {
    display: none;
}

.openclose {
    display: none;
    position: fixed;
    left: 20px;
    top: 20px;
    z-index: 2010;
    a {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
        &:hover {
            opacity: .8;
        }
    }
    a.openclose__open {
        background-image: url('../images/open.svg');
    }
    a.openclose__close {
        display: none;
        background-image: url('../images/close.svg');
    }
}

.body_menu_opened {
    overflow: hidden!important;
    height: 100vh;
    a.openclose__open {
        display: none;
    }
    a.openclose__close {
        display: block;
    }
    .h_size_tablet {
        .header {
            border-bottom: 0;
            .header__search {
                border-left: 1px solid white;
                border-bottom: 1px solid white;;
            }
            .header__cart {
                right: -100%;
                border-bottom: 1px solid white;
            }
        }
        .menu_size_superbig {
            top: 59px;
            pointer-events: auto;
        }
    }
    .h_size_mobile {
        .header {
            border-bottom: 0;
            .header__search {
                border-left: 1px solid white;
                border-bottom: 1px solid white;;
            }
            .header__cart {
                right: -100%;
                border-bottom: 1px solid white;
            }
        }
        .menu_size_supersmall {
            top: 39px;
            pointer-events: auto;
        }
    }
}
