.basket {
    
    &__item {
        @include cf();
        padding: 30px 0;
        border-bottom: 1px solid $black;

        &:last-child {
            border-bottom: 0;
        }
    }
    &__image {
        @include col(7.5);
        min-height: 400px;
        width: calc((100%) - 250px);
        background-repeat: no-repeat;
        background-position: top center;
        background-size: contain;
        img {
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }
    }

    &__artist {
        @include mfont();
        font-size: 1.875rem;
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
        position: relative;
        text-transform: uppercase;
    }

    &__title {
        font-weight: 500;
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 10px;
    }

    &__desc {
        font-size: 0.875rem;
        text-align: center;
        font-weight: 400;
        margin-bottom: 40px;
    }

    &__wrapper {
        @include col-offset(0.5);
        @include cf();
    }
    &__wrap {
        @include col(3);
        @include col-offset(0.5);
        padding-bottom: 30px;

        width: 220px;
        margin-left: 30px;

        .work__price {
            width: 100%;
            float: none;
            padding: 20px 0;
        }

        .work__price-remove {
            margin-bottom: 15px;
        }
    }
}

.basket__order {
    display: none;
    text-align: center;
    form {
        max-width: 60%;
        margin: 40px auto 0;
    }

    .form-group {
        margin-bottom: 25px;
        label {
            display: block;
            text-align: center;
            margin-bottom: 5px;
            font-weight: 600;
        }
    }
    .field-orders-title,
    .field-orders-email {
        float: left;
        width: 48%;
        box-sizing: border-box;
    }
    .field-orders-title {
        margin-right: 4%;
    }

    .field-orders-text {
        margin-bottom: 5px;
    }

    .form-control {
        box-sizing: border-box;
        width: 100%;
        padding: 0 10px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #F4F4F4;
        background-color: #F4F4F4;
    }

    .has-success {
        label {
            color: $black;
        }
    }

    .has-error {
        .form-control {
            border: 1px solid #A8355C;
        }
    }

    textarea.form-control {
        height: 150px;
    }

}