@import 'common/vars';
@import 'common/mixins';
@import 'common/fonts';
@import 'common/action-button';
@import 'common/cp';

@import 'desktop.blocks/b-headline';
@import 'desktop.blocks/b-page';
@import 'desktop.blocks/footer';
@import 'desktop.blocks/main';
@import 'desktop.blocks/nav';

@import 'desktop.blocks/albums';
@import 'desktop.blocks/artists';
@import 'desktop.blocks/basket';
@import 'desktop.blocks/events';
@import 'desktop.blocks/news';
@import 'desktop.blocks/newsletter';
@import 'desktop.blocks/pick';
@import 'desktop.blocks/search';
@import 'desktop.blocks/share';

@media only screen and (max-width: $desktop - 1) {
	@import 'tablet.blocks/b-page';
	@import 'tablet.blocks/main';
	@import 'tablet.blocks/artists';
	@import 'tablet.blocks/events';
	@import 'tablet.blocks/albums';
	@import 'tablet.blocks/search';
	@import 'tablet.blocks/news';
}

@media only screen and (max-width: $tablet - 1) {
	@import 'mobile.blocks/b-page';
	@import 'mobile.blocks/main';
	@import 'mobile.blocks/artists';
	@import 'mobile.blocks/events';
	@import 'mobile.blocks/albums';
	@import 'mobile.blocks/search';
	@import 'mobile.blocks/news';
	@import 'mobile.blocks/pick';
	@import 'mobile.blocks/footer';
	@import 'mobile.blocks/basket';
}
@import "../fancybox/jquery.fancybox.css";
@import "../swiper/swiper.min.css";
@import "../fotorama/fotorama.css";
@import "../lightslider/lightslider.css";


img, iframe {
	max-width: 100%;
}
/* This element defines the size the iframe will take.
   In this example we want to have a ratio of 25:14 */
.responsive-video {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%; /* The height of the item will now be 56.25% of the width. */
}

/* Adjust the iframe so it's rendered in the outer-width and outer-height of it's parent */
.responsive-video iframe {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
}
