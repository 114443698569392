.search-form-block {
    &__wrap {
        width: 80%;
    }
}

.search-form {
    &__group {
        display: inline-block;
        margin: 0 0 30px 0;
        &_name_price {
            width: 47.5%;
            margin-right: 5%;
        }
        &_name_artist {
            width: 47.5%;
        }
        &_name_color,
        &_name_ganre {
            width: 47.5%;
        }
        &_name_size,
        &_name_technique {
            width: 47.5%;
            margin-right: 5%;
        }

    }
}
