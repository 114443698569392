.registration-wait-for-confirmation,
.password-recovery-success,
.login,
.confirm-email,
.change-own-password-success,
.change-own-password,
.user-registration,
.password-recovery {
    width: 1000px;
    margin: 100px auto;
    padding: 50px;
    background: rgba(0,0,0,.8);
    border-radius: 15px;
}
.login h2,
.change-own-password h2,
.user-registration h2,
.password-recovery h2 {
    text-align: center;
    font-size: 30px;
    line-height: 1.5em;
    margin: 0 0 0.5em 0;
}

.password-recovery-success,
.registration-block {
    width: 460px;
    margin: 0 auto;
    padding: 10px 0 0 0;
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
    color: #ccc;
}

.form-control {
    font-weight: normal;
}

.form-group .checkbox {
    line-height: 24px;
}