.album-card {
    @include col(10);
    @include col-offset(1, true);
    margin-bottom: 20px;
    padding: 40px 0;

    &:first-child,
    &:nth-child(3n+1) {
        @include col-offset(1, true);
    }
}