
.news__item_size_full {
    @include cf();

    padding-top: 40px;



    .news__date {
        text-align: center;
        margin-bottom: 10px;
    }

    .fr-arrows_mode_right,
    .fr-arrows_mode_left {
        background-image: none;
        opacity: 1;
    }
    .fr-arrows_mode_left:after {
        display: block;
        content: "";
        width: 40px;
        height: 40px;
        border: 1px solid #C1C1C1;
        position: absolute;
        left: -21px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        opacity: 1;
        transition: border .3s;
        background-color: white;
        background-image: url(../images/fr-arrows_mode_left_size_small.svg);
        background-repeat: no-repeat;
        background-position: center;
    }
    .fr-arrows_mode_left:hover:after {
        border: 1px solid #414042;
    }

    .fr-arrows_mode_right:after {
        display: block;
        content: "";
        width: 40px;
        height: 40px;
        border: 1px solid #C1C1C1;
        position: absolute;
        right: -21px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        opacity: 1;
        transition: border .3s;
        background-color: white;
        background-image: url(../images/fr-arrows_mode_right_size_small.svg);
        background-repeat: no-repeat;
        background-position: center;
    }
    .fr-arrows_mode_right:hover:after {
        border: 1px solid #414042;
    }

    .news__page-title {
        font-size: 1rem;
    }

    .news__title {
        font-size: 4em;
        font-family: $museo;
        font-weight: 300;
        margin: 0 auto 20px;
        text-align: center;
        max-width: 80%;
        line-height: 1.2em;
    }

    .news__info {
        color: $black;
        position: relative;
        transform: none;
        left: auto;
        top: auto;
        text-align: left;
        width: 80%;
        margin: 0 auto;
    }
    .news__desc {
        padding-bottom: 25px;
    }
    .news__pdfs {
        margin-bottom: 20px;
    }

    .news__images {
        margin-bottom: 40px;

        max-height: 540px;
        overflow: hidden;

        &.fotorama {
            overflow: auto;
            max-height: inherit;
        }

        &-wrap {
            position: relative;

            img {
                opacity: 0;
            }

            .fotorama__img {
                opacity: 1;
            }
            .fotorama__stage {
                padding-bottom: 31px;
            }
            .fotorama__nav-wrap {
                background: #f9f9f9;
            }
            .fotorama__dot {
                border-color: rgba(0,0,0,1);
                background: rgba(0,0,0,1);
            }
            .fotorama__nav--dots .fotorama__nav__frame {
                width: 20px;
                height: 40px;
            }
            .fotorama__nav__frame .fotorama__dot {
                margin: 0 auto;
                left: 0;
                top: 17px;
            }
            .fotorama__nav__frame.fotorama__active .fotorama__dot {
                border-width: 1px;
                width: 8px;
                height: 8px;
                top: 15px;
                background: rgba(0,0,0,1);
            }

            .fotorama__caption__wrap {
                display: block;
                background: #f9f9f9;
                text-align: center;
                p {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

        }
    }

    .news__information {
        text-align: center;
        margin-bottom: 20px;
    }
    .news__information:empty {
        display: none;
    }
    .news__address {
        border-top: 1px solid $black;
    }
    .news__address-title {
        text-align: center;
        width: 60%;
        margin: 0 auto 20px;
    }
    .news__address-map {
        font-size: 0;

        iframe {
            display: block;
        }
    }
    .news__magazine {
      text-align: center;
      margin: 40px 0;
      img {
        max-width: 300px;
      }
    }
    .news__goods {
        border-top: 1px solid $black;

        &-wrap {
            @include col(11);
            @include col-offset(0.5);
            padding: 0 0 40px 0;
        }
    }

}

.news {
    &__slider {

        height: 500px;

        &-container {
            height: 500px;
        }

        .fotorama__nav-wrap {
            position: relative;
            top: -40px;
        }
        .fotorama__dot {
            border-color: rgba(255,255,255,.7);
            background: rgba(255,255,255,.7);
        }
        .fotorama__nav--dots .fotorama__nav__frame {
            width: 40px;
            height: 40px;
        }
        .fotorama__nav__frame.fotorama__active .fotorama__dot {
            border-width: 1px;
            width: 11px;
            height: 11px;
            left: 3px;
            top: 9px;
            background: rgba(255,255,255,.7);
        }
        &-wrap {
            position: relative;
        }
    }
}

.news-item {
    position: relative;

    &__info {
        color: white;
        transform: translateX(-50%);
        position: absolute;
        width: 50%;
        left: 50%;
        top: 0;
        text-align: center;

        &-wrap {
            padding: 0 20px;
            height: 100%;
        }
    }
    &__title {
        @include fheader();
        font-size: 2.5rem;
        line-height: 1em;
        text-transform: none;
        margin-bottom: .5em;
        a {
            text-decoration: none;
            color: white;
        }
    }

    &__image {
        @include background-cover();
        width: 100%;
        height: 100%;
        position: relative;
    }
    &__information {
        font-size: 0.875rem;
    }
    &__city {
        font-weight: 700;
    }
    &__gradient {
        background: linear-gradient(to bottom,#000000ba 0,rgba(0,0,0,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 );

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 300px;
        &_mode_revers {
            top: auto;
            bottom: 0;
            transform: rotateX(180deg);
        }
    }

    &_size_big {
        width: 100%;
        height: 500px;
        padding-bottom: 35px;
        .news-item__info {
            height: 250px;
            box-sizing: border-box;
            padding: 45px 0;
        }
        .news-item__information {
            position: relative;
            // bottom: 25px;
            width: 100%;
        }
    }
}
