.search-form-block {
    &__wrap {
        width: auto;
        margin: 0 20px;
    }
}

.search-form {
    &__group {
        display: inline-block;
        margin: 0 0 30px 0;
        &_name_price {
            width: 100%;
            margin-right: 0;
        }
        &_name_artist {
            width: 100%;
            margin-right: 0;
        }
        &_name_color,
        &_name_ganre {
            width: 100%;
            margin-right: 0;
        }
        &_name_size,
        &_name_technique {
            width: 100%;
            margin-right: 0;
        }

    }
}
