.b-page .b-headline h1,
.b-page .b-headline h2,
.b-page .b-headline h3,
.b-page .b-headline h4,
.b-page .b-headline h5 {
    text-transform: uppercase;
    margin-bottom: 1.5em;
    text-align: center;
}

.b-page .b-headline h1 {
    font-size: 2em;
}

.b-page .b-headline h2 {
    font-size: 1.8em;
}

.b-page .b-headline h3 {
    font-size: 1.6em;
}

.b-page .b-headline h4 {
    font-size: 1.4em;
}

.b-page .b-headline h5 {
    font-size: 1.2em;
}
