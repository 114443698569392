.news__item_size_full {
    padding-top: 10px;

    .fr-arrows_mode_left:after {
        width: 30px;
        height: 30px;
        left: 20px;
        bottom: -12px;
        top: auto;
    }
    .fr-arrows_mode_right:after {
        width: 30px;
        height: 30px;
        right: 20px;
        bottom: -12px;
        top: auto;
    }
}
