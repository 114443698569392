// Mixins.
@mixin nl {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style: none;
    list-style-position: outside;
}
@mixin cf {
    &:before,
    &:after {
        display: table;
        content: "";
    }
    &:after {
        clear: both;
    }
}
@mixin clear {
    display: table;
    height: 0;
    clear: both;
}
// You can add your own mixins here:
@mixin respond-to($media) {
    @if $media == phone {
        @media only screen and (max-width: $tablet - 1) {
            @content;
        }
    } @else if $media == tablet {
        @media only screen and (max-width: $desktop - 1) {
            @content;
        }
    } @else if $media == desktop {
        @media only screen and (max-width: $large - 1) {
            @content;
        }
    } @else if $media == large {
        @media only screen and (min-width: $large) {
            @content;
        }
    }
}
// Column mixin
@mixin col($col, $padding: false/*, $firstcolumn: false, $lastcolumn:false*/) {
    // width: $col-width*$col+$gap*$col*2 - if($first, 20, 0) - if($last, 20, 0);
    width: 100%/12*$col;
    float: left;
    padding: 0 if($padding, $gap, 0);
    box-sizing: border-box;
    background-clip: padding-box !important;
}
@mixin col-offset($col, $right: false) {
    $col-width: 100%/12;
    margin-left: $col-width*$col;
    margin-right: if($right, $col-width*$col, 0);
}
@mixin fdefault() {
    font-family: $helvetica;
    font-size: 12px;
}
@mixin fheader() {
    font-family: $museo;
    font-size: 20px;
    line-height: 1em;
    text-transform: uppercase;
    margin-bottom: 1em;
    text-align: center;
    font-weight: 700;
}
@mixin mfont() {
    font-family: $museo;
    font-size: 1rem;
    line-height: 1em;
}
@mixin imagecenter() {
    width: 120%;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}
@mixin background-cover() {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
@mixin background-contain() {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
