.pick__slider-wrap .fr-arrows_mode_left {
	left: 0;
	margin-top: 0;
	top: 0;
	&:hover {
		left: 0;
	}
}
.pick__slider-wrap .fr-arrows_mode_right {
	right: 0;
	margin-top: 0;
	top: 0;
	&:hover {
		right: 0;
	}
}

.pick__title {
	width: 75%;
	margin: 0 auto;
}