
.album-card {
    @include col(3.8);
    @include col-offset(0.3);
    margin-bottom: 20px;
    padding: 40px;

    &:first-child,
    &:nth-child(3n+1) {
        margin-left: 0;
    }

    a {
        text-decoration: none;
    }

    &__image {
        width: 60px;
        height: 60px;
        margin: 0 0 20px 0;
        @include background-cover();
        position: relative;
        float: left;
    }

    &__title {
        margin-left: 80px;
    }
    &__price {
        margin-left: 80px;
    }
    &__artist {
        margin-left: 80px;
        @include fheader();
        font-size: 1.375rem;
        margin-bottom: 10px;
        text-transform: none;
        text-align: left;
        text-decoration: underline;
    }
}

.album {

    &__info {
        padding-top: 30px;
        position: relative;
    }

    &__right-part {
        padding-left: 69.16667%;
        &-wrap {
            padding: 0 20px;
        }
    }

    &__author {
        @include mfont();
        font-size: 1.875rem;
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
        position: relative;
        text-transform: uppercase;
    }

    &__author,
    &__title {
        font-weight: 500;
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 10px;
    }
    &__title {
        margin-bottom: 25px;
    }

    &__images-wrap {
        @include col(8);
        @include col-offset(0.3);
        // height: 500px;
        margin-bottom: 20px;

        position: relative;

        .fotorama__nav-wrap {
        }
        .fotorama__dot {
            border-color: rgba(0,0,0,.7);
            background: rgba(0,0,0,.5);
        }
        .fotorama__nav--dots .fotorama__nav__frame {
            width: 40px;
            height: 40px;
        }
        .fotorama__nav__frame .fotorama__dot {
            margin: 0 auto;
            left: 0;
            top: 17px;
        }
        .fotorama__nav__frame.fotorama__active .fotorama__dot {
            border-width: 1px;
            width: 11px;
            height: 11px;
            top: 13px;
            background: rgba(0,0,0,.5);
        }
    }
    &__image {
        height: 100%;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    &__youtube {
        height: 100%;
    }

    &__prices {
        padding: 0 0 25px 0;
        @include cf();
    }

    &__price {
        padding: 0 0 30px 0;
        margin-left: 0;

        &-price {
            font-size: 1rem;
            line-height: 1rem;
            font-weight: 600;
            margin-bottom: 25px;
        }
        &-description {
            font-size: 1rem;
            line-height: 1rem;
            margin-bottom: 25px;
        }
        &-buy {
            .action-button {
                font-size: 1rem;
                font-weight: 700;
                padding: 5px 20px;
                height: auto;
                line-height: 20px;
                white-space: inherit;
                max-width: 160px;
                &:before {
                    display: none;
                }
            }
        }
    }

    &__text {
        border-top: 1px solid $black;
        margin-top: 40px;
        &-wrap {
            @include col-offset(2, true);
            @include cf();
            padding-bottom: 40px;
        }
    }

}