.footer {
    background-color: #F2F2F2;
    background-image: url(/static/footer.jpg);
    margin-top: 40px;
    padding: 40px;
    margin-bottom: 60px;

    &__wrap {
        max-width: 500px;
        margin: 0 auto;
        background-color: #fff;
        padding: 30px 60px;
    }

    &__logotype {
        margin: 0 0 25px 0;
        height: 45px;
        text-align: center;
        img {
            width: 78px;
            height: 45px;
        }
    }

    &__cart {
        @include mfont();
        font-size: 16px;
        text-transform: uppercase;
        text-align: center;
        margin: 0 0 20px 0;
    }

    &__menu {
        margin: 0 0 20px 0;
        > ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: inline;

            li {
                list-style: none;
                margin: 0;
                padding: 10px 0;
                display: inline;
                @include mfont();
                font-size: 16px;
                text-transform: uppercase;
                text-align: center;
                a {
                    margin: 0 5px;
                }
            }
            > li {
                display: block;
            }
        }
        ul {
            display: inline;
        }
    }

    &__title {
        text-align: center;
        margin-bottom: 25px;
        line-height: 25px;
    }

    &__copy {
        text-align: center;
        margin-bottom: 30px;
        opacity: .8;
    }

    &__links {
        text-align: center;
        font-size: 16px;
        line-height: 25px;
        a {
            margin: 0 15px;
        }
    }

}

.footer-dd {

    &__dev {
        text-align: center;
        line-height: 1.5em;
    }
    &__picture {
        text-align: center;
    }
}
