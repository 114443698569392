.wrap {
    padding: 0;
}

.border {
    border: 0;
}


.h_size_mobile,
.h_size_desktop {
    display: none;
}
.h_size_tablet {
    display: block;

    .header__placer {
        height: 60px;
    }

    .header {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 2000;
        background: $white;
        height: 60px;
        box-sizing: border-box;
        padding: 0;
        border: 0;
        border-bottom: 1px solid black;
        h1 {
            margin: 0;
            line-height: 60px;
        }
        .header__search {
            position: absolute;
            right: 0;
            top: 0;
            width: 60px;
            height: 60px;
            border: 0;
            box-sizing: border-box;
            border-left: 1px solid black;
            border-bottom: 1px solid black;
            background: $white;
            float: none;
            margin: 0;
            z-index: 2001;
            a {
                padding: 0;
                height: 60px;
                width: 60px;
                line-height: 60px;
                background-size: 30px 30px;
            }
        }
    }

    .header__cart {
        line-height: 60px;
        padding: 0 20px;
        border-left: 1px solid black;
        border-bottom: 1px solid black;
        box-sizing: border-box;
        height: 60px;
        position: absolute;
        right: 60px;
        top: 0;
        background: white;
        margin: 0;
        font-size: 18px;
        transition: right .3s linear;
    }

    .header__logotype {
        width: 110px;
        height: 36px;
        margin: 11px auto;
        img {
            width: 110px;
            height: 36px;
        }
    }

    .menu_size_superbig {
        position: fixed;
        left: 0;
        border-top: 1px solid white;
        top: -100%;
        width: 100%;
        height: calc((100vh) - 60px);
        background-color: $white;
        z-index: 1999;
        margin: 0;
        transition: top .3s;
        pointer-events: none;

        a.menu__item {
            width: 100%;
            display: block;
            border-right: 0 !important;
            border-bottom: 1px solid $black;
            line-height: 80px;
            height: 80px;
        }
        .header__titles {
            position: static;
            top: auto;
            transform: none;
            left: auto;
            margin: 0;
            border-bottom: 1px solid black;
            padding-bottom: 15px;
        }

        > ul {
            margin: 0; padding: 0;
            display: block;

            > li {
                width: 100%;
                margin: 0;
                padding: 0;
                border-bottom: 1px solid black;
                position: relative;

                > a {
                    padding: 17px 15px 38px;
                    display: block;
                }
                > a:first-child:last-child {
                    padding: 27px 15px 27px;
                }

                > ul {
                    display: block;
                    position: absolute;
                    left: auto;
                    bottom: 10px;
                    right: auto;
                    top: auto;
                    opacity: 1;
                }

            }
        }

    }
    .header__lang {
        position: absolute;
        right: 0;
        bottom: 0;
        background: $white;
        float: none;
        margin: 0;
        z-index: 2001;
        width: 50%;
        height: 80px;
        box-sizing: border-box;
        border-top: 1px solid $black;

        a {
            border-right: 0;
            width: 100%;
            display: block;
            line-height: 30px;
            padding: 25px 0;
            border-bottom: 0;
            font-size: 18px;
        }
    }

    .header__cart-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        background: #d54118;
        color: white;
        float: none;
        margin: 0;
        z-index: 2001;
        width: 50%;
        height: 80px;
        box-sizing: border-box;
        border-top: 1px solid $black;
        border-right: 1px solid $black;
        a {
            color: white;
            border-right: 0;
            width: 100%;
            display: block;
            line-height: 30px;
            padding: 25px 0;
            border-bottom: 0;
            font-size: 18px;
        }
    }
}
.openclose {
    display: block;
}
