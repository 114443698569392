.pick {

    &__slider {
        // height: 540px;
        overflow: hidden;

        &-container {
            opacity: 0;
            width: 100%;
            height: 450px;
            overflow: hidden;
        }
        &-wrap {
            padding-top: 30px;
            position: relative;

            .fr-arrows {
                height: 450px;
                top: 20px;
                &_mode_left {
                    left: 20px;
                    margin-left: 0;
                    margin-top: -25px;

                    &:hover {
                        left: 15px;
                    }
                }
                &_mode_right {
                    right: 20px;
                    margin-right: 0;
                    margin-top: -25px;
                    &:hover {
                        right: 15px;
                    }
                }
            }
            .swiper-container-autoheight .swiper-wrapper {
                transition-duration: 400ms !important;
            }
            .swiper-pagination-bullets {
                position: absolute;
                left: 0;
                right: 0;
                top: 400px;
                height: 40px;
                text-align: center;
                z-index: 1;
            }
            .swiper-pagination-bullet {
                width: 40px;
                height: 40px;
                margin: 0 !important;
                border-radius: 0;
                position: relative;
                display: inline-block;
                vertical-align: top;
                // opacity: .5;
                // transition: all .4s;
                background: none;

                &:hover {
                    opacity: 1;
                }
            }
            .swiper-pagination-bullet:after {
                display: block;
                content: "";
                width: 8px;
                height: 8px;
                background: #fff;
                border-radius: 50%;
                display: inline-block;
                position: absolute;
                top: 16px;
                left: 16px;
            }
            .swiper-pagination-bullet-active {
                top: 0;
            }
            .swiper-pagination-bullet-active:after {
                width: 16px;
                height: 16px;
                position: absolute;
                left: 12px;
                top: 12px;
            }
            .action-button {
                font-size: 1.5rem;
                line-height: 1.1em;
                height: auto;
                border-color: white;
                background: none;
                color: white;
                padding: 10px 20px;

                &:hover {
                    outline-color: white;
                }
            }
            .swiper-button-disabled {
                opacity: 0;
                pointer-events: none;
            }
            .swiper-slide-active {
                .pick__info {
                    opacity: 1;
                }
            }
        }
    }

    &__infotitle {
        @include fheader();
        padding-top: 40px;
        letter-spacing: 1px;
    }


    &__supertitle {
        font-family: $museo;
        // font-size: 2.75rem;
        text-align: center;
        margin-bottom: 20px;
        line-height: 1em;
        font-weight: 600;
        color: white;
        text-transform: uppercase;
    }
    &__title {
        font-family: $museo;
        font-size: 2.75rem;
        text-align: center;
        margin-bottom: 20px;
        line-height: 1em;
        font-weight: 300;
        color: white;
    }
    &__date {
        color: white;
    }

    &__item {
        @include cf();
        .text-show { display: block; }
        .text-hide { display: none; }

        &_opened_yes {
            .text-show { display: none; }
            .text-hide { display: block; }
        }
    }

    &__date {
        text-align: center;
        margin-bottom: 15px;
    }

    &__image {
        height: 450px;
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
        padding-top: 30px;
        box-sizing: border-box;
    }
    &__info {
        @include col(11);
        @include col-offset(0.5);
        overflow: hidden;
        background: white;
        opacity: 0;
        transition: opacity .4s;
    }
    &__information {
        padding: 0 0 40px 0;
    }
    &__switch {
        position: absolute;
        top: 320px;
        left: 0;
        width: 100%;
        text-align: center;
    }
}