html, body {
    overflow: hidden !important;
    overflow-y: auto !important;
}
.wrap {
    padding: 0;
    width: 100%;
    max-width: 100vw;
}
.border {
    border: 0;
}
.h_size_tablet,
.h_size_desktop {
    display: none;
}
.h_size_mobile {
    display: block;
    .header__placer {
        height: 40px;
    }
    .header {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 2000;
        background: $white;
        height: 40px;
        box-sizing: border-box;
        padding: 0;
        border: 0;
        border-bottom: 1px solid black;
        h1 {
            margin: 0;
            line-height: 60px;
        }
        .header__search {
            position: absolute;
            right: 0;
            top: 0;
            width: 40px;
            height: 40px;
            border: 0;
            box-sizing: border-box;
            border-left: 1px solid black;
            border-bottom: 1px solid black;
            background: $white;
            float: none;
            margin: 0;
            z-index: 2001;
            a {
                padding: 0;
                height: 40px;
                width: 40px;
                line-height: 40px;
                background-size: 25px 25px;
            }
        }
    }
    .header__cart {
        line-height: 40px;
        padding: 0 20px;
        border-left: 1px solid black;
        border-bottom: 1px solid black;
        box-sizing: border-box;
        height: 40px;
        position: absolute;
        right: 40px;
        top: 0;
        background: white;
        margin: 0;
        font-size: 18px;
        transition: right .3s linear;
    }
    .header__logotype {
        width: 110px;
        height: 30px;
        margin: 5px auto;
        img {
            width: 110px;
            height: 100%;
        }
    }
    .menu_size_supersmall {
        position: fixed;
        left: 0;
        border-top: 1px solid white;
        top: -100%;
        width: 100%;
        height: calc((100vh) - 40px);
        background-color: $white;
        z-index: 1999;
        margin: 0;
        transition: top .3s;
        pointer-events: none;
        a.menu__item {
            width: 100%;
            display: block;
            border-right: 0 !important;
            border-bottom: 1px solid $black;
            line-height: 80px;
            height: 80px;
        }
        > ul {
            margin: 0;
            padding: 0;
            display: block;
            position: relative;
            > li {
                width: 100%;
                margin: 0;
                padding: 0;
                border-bottom: 1px solid black;
                position: relative;
                > a {
                    padding: 11px 15px;
                    display: block;
                }
                > ul {
                    display: none;
                    position: relative;
                    top: 0;
                }
                &.active ul {
                    display: flex;
                    flex-direction: column;
                    opacity: 1;
                }
            }
        }
    }
    .header__lang {
        position: absolute;
        right: 0;
        bottom: 0;
        background: $white;
        float: none;
        margin: 0;
        z-index: 2001;
        width: 50%;
        height: 40px;
        box-sizing: border-box;
        border-top: 1px solid $black;
        a {
            border-right: 0;
            width: 100%;
            display: block;
            line-height: 30px;
            padding: 5px 0;
            border-bottom: 0;
            font-size: 18px;
        }
    }
    .header__cart-footer {
        position: absolute;
        left: 0;
        bottom: 0;
        background: #d54118;
        color: white;
        float: none;
        margin: 0;
        z-index: 2001;
        width: 50%;
        height: 40px;
        box-sizing: border-box;
        border-top: 1px solid $black;
        border-right: 1px solid $black;
        a {
            color: white;
            border-right: 0;
            width: 100%;
            display: block;
            line-height: 30px;
            padding: 5px 0;
            border-bottom: 0;
            font-size: 18px;
        }
    }
}
.openclose {
    left: 10px;
    top: 10px;
}
