.blog {
    &__allpart {
        opacity: 0;
        transition: opacity .2s;
    }
}

.grid-item {
    transition: transform .4s, left .4s, top .4s, opacity .4s;
}

.events_type_archive {
    padding-top: 20px;
}

.events_type_middle {
    padding-top: 20px;
}

.events_type_current.events_mode_index {

    border-bottom: 1px solid #bdbdbd;

    padding-bottom: 40px;

    .event-item:last-child {
        border-bottom: 0;
    }
}

.event-item {
    &__city {
        font-weight: bold;
        display: inline;
    }
    &__address {
        display: inline;
    }
    &__magazine {
        img {
            max-width: 100%;
        }
    }
}
.event-item__city + .event-item__address:before {
    content: ", ";
}
.event-item__city + .event-item__address:empty:before {
    content: "";
}

.events_type_archive .events__allpart,
.events_type_middle .events__allpart {
    display: flex;
    flex-flow: row wrap;
}

.event-item_size_small {
    @include col(2.8);
    @include col-offset(0.2);

    float: none;

/*
    &:nth-child(3n+1) {
        margin-left: 0;
    }

    &:nth-child(6n+1) {
        @include col-offset(0.2);
    }
*/
    .event-item {
        &__image {
            height: 100px;
            @include background-contain();
            background-position: left center !important;
            //background-position: center center!important;
            margin-bottom: 10px;
        }
        &__title {
            @include fheader();
            text-align: left;
            font-weight: normal;
            font-size: 18px;
            text-transform: none;
        }
        &__info {
            max-width: 200px;
            margin-bottom: 20px;
        }
    }
}

.event-item_size_middle {
    @include col(3.8);
    @include col-offset(0.2);

    margin-bottom: 20px;

    float: none;

/*
    &:nth-child(3n+1) {
        margin-left: 0;
    }

    &:nth-child(6n+1) {
        @include col-offset(0.2);
    }
*/
    .event-item {
        &__image {
            height: 150px;
            @include background-contain();
            background-position: left center !important;
            //background-position: center center!important;
            margin-bottom: 10px;
        }
        &__title {
            @include fheader();
            text-align: left;
            font-weight: normal;
            font-size: 18px;
            text-transform: none;
        }
        &__info {
            max-width: 200px;
            margin-bottom: 20px;
        }
    }
}

.event-item_type_card {

    box-sizing: border-box;
    border: 1px solid $black;
    width: 31%;

    a {
        display: block;
        text-decoration: none;

        &:hover {
            color: black !important;
            .event-item {
                &__title {
                    text-decoration: none;
                }
                &__info {
                    background: #F4F4F4;
                }
            }
        }
    }

    .event-item {
        &__type-title {
            text-align: center;
            font-family: $museo;
            margin: -10px auto 0;
            text-transform: uppercase;
            span {
                background: white;
                padding: 0 15px;
                color: #848484;
            }
        }

        &__image {
            height: auto;
            width: 100%;
            padding: 20px 0 30px;
            border-bottom: 1px solid #bdbdbd;

            img {
                max-width: calc((100%) - 60px);
                height: auto;
                display: block;
                margin: 0 auto;
            }
        }
        &__title {
            @include fheader();
            text-align: left;
            font-weight: normal;
            font-size: 22px;
            text-transform: none;
            text-decoration: underline;
        }
        &__info {
            padding: 30px;
        }
    }
}

.gutter-sizer {
    width: 3.5%;
}

.event-item_size_big {
    @include cf();
    border-bottom: 1px solid #bdbdbd;
    height: 300px;
    padding: 30px 0;

    &:last-child {
        // border-bottom: 1px solid #bdbdbd;
    }
    .event-item {
        &__image {
            @include col(5.7);
            @include col-offset(0.3);
            @include background-contain();
            height: 100%;
            //background-position: left center !important;
            background-position: center center!important;
            a {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        &__info {
            @include col(5.4);
            @include col-offset(0.3);
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
        }
        &__title {
            @include fheader();
            text-transform: none;
            font-size: 30px;
            text-align: left;
            font-weight: normal;
        }
    }
}
